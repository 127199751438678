import React from 'react'
import { Modal } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import {StyledModal,StyledTitle,StyledBody, CompayContainer, CompanyButton, InitiativesWrapper} from './styles';
import { connect } from "react-redux";
import { ImageUrl } from '../../../utils/constants';

class CompanyInitiative extends React.Component {

  showWellnessInitiatives = (companyInitiatives) => (
    companyInitiatives && companyInitiatives.length && companyInitiatives.map((data, index) => (
      <CompayContainer key={index}    onClick={() => this.props.companyPopup(data)}>
        <CompanyButton>
          <div className='image_cls'>
            <img src={`${ImageUrl}/${data.initiative_image}`} />
          </div>
          <div className="name">{data.initiative_name}</div>
        </CompanyButton>
      </CompayContainer>))
  );
  render() {
    const { showModal,onClose,companyInitiatives} = this.props;
    return (
      <StyledModal transform={1} show={showModal}    newWidth={"530px"}  newBg={'newBg'} paddingBottom="15px" height="50px">
        <Modal.Header closeButton onHide={() => onClose()}>
          <div className='cross_img' style={{float: 'right' ,  cursor: "pointer"}}>
            <img src="/public/images/AdminEngagement/Cross_Icon_Img.png" onClick={() => onClose()} style={{width: "24px", height: "24px" , cursor: "pointer" }}/>
          </div>
          <StyledTitle>Company Initiatives</StyledTitle>
        </Modal.Header>
        <StyledBody>
          <StyledTitle color={'color'}>{companyInitiatives && companyInitiatives.length >0?"Select a Company Initiative":"No Comapny Initiatives"}</StyledTitle>
          <InitiativesWrapper>{companyInitiatives && companyInitiatives.length >0 && this.showWellnessInitiatives(companyInitiatives)}</InitiativesWrapper>
        </StyledBody>
      </StyledModal>    
    ) ;
  }

}  
CompanyInitiative.propTypes = {
  showModal: PropTypes.bool.isRequired,
  companyPopup: PropTypes.func,
  onClose: PropTypes.func,
  companyInitiatives: PropTypes.array,
}

const mapStateToProps = (state) => ({
  companyInitiatives: state.companyDashboard.companyInitiatives  
});

export default connect(mapStateToProps, null)(CompanyInitiative);

